<template>
  <div class="commercial">
    <headert></headert>
    <div class="banner">
      <img src="../../assets/image/personnel/banner3.png" alt />
    </div>
    <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
    <el-row class=" WJgroupIntroduce-Box contentBG" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class="comContent">
          <div class="padding-tb70" style="padding-bottom: 40px;">
            <div class="WJgroup-titleBox flex">
              <p class="WJgroup-title">联系方式</p>
            </div>
          </div>
          <div class='contact_ConBox'>
            <div class="contact_ConOne">
              <h1>
                维佳集团
              </h1>
              <div class="contact_ConOneMsg">
                <i class="el-icon-location contact_Conicon"></i>
                <div>
                  <p>地址</p>
                  <p>湖北省武汉市洪山区书城路文治街维佳创意大厦西区25楼</p>
                </div>
              </div>
              <div class="contact_ConOneMsg">
                <i class="el-icon-phone contact_Conicon"></i>
                <div>
                  <p>联系电话</p>
                  <p>027-87871766</p>
                </div>
              </div>
              <div class="contact_ConOneMsg">
                <i class="el-icon-menu contact_Conicon"></i>
                <div>
                  <p>公众号</p>
                  <p>维佳集团</p>
                </div>
              </div>
            </div>
            <div class="contact_ConOneTwoBox flex">
              <div class="contact_ConOne" style="width: 40%;">
                <h1>
                  佰港酒店
                </h1>
                <div class="contact_ConOneMsg">
                  <i class="el-icon-location contact_Conicon"></i>
                  <div>
                    <p>地址</p>
                    <p>武汉市洪山区丁字桥南路529号</p>
                  </div>
                </div>
                <div class="contact_ConOneMsg">
                  <i class="el-icon-phone contact_Conicon"></i>
                  <div>
                    <p>联系电话</p>
                    <p>027-87225555</p>
                  </div>
                </div>
              </div>
              <div class="contact_ConOne" style="width: 59%;margin-left: 1%;">
                <h1>
                  青舍酒店
                </h1>
                <div class="contact_ConOneMsg">
                  <i class="el-icon-location contact_Conicon"></i>
                  <div>
                    <p>地址</p>
                    <p>武汉市洪山区文昌路30号（理工大学南门斜对面）</p>
                  </div>
                </div>
                <div class="contact_ConOneMsg">
                  <i class="el-icon-phone contact_Conicon"></i>
                  <div>
                    <p>联系电话</p>
                    <p>027-87569999</p>
                  </div>
                </div>
              </div>

            </div>
            <div class="contact_ConOne">
              <h1>
                文化公司-影院
              </h1>
              <div class="contact_ConOneMsg">
                <i class="el-icon-location contact_ConOneicon"></i>
                <div>
                  <p>维佳影院（佰港城店）：湖北省武汉市洪山区南湖文治街30号维佳佰港城4层</p>
                </div>
              </div>
              <div class="contact_ConOneMsg">
                <i class="el-icon-location contact_ConOneicon"></i>
                <div>
                  <p>维佳影院（盘龙城店）：湖北省武汉市黄陂区盘龙城经济开发区巨龙大道39号武汉环球广场4层</p>
                </div>
              </div>
              <div class="contact_ConOneMsg">
                <i class="el-icon-location contact_ConOneicon"></i>
                <div>
                  <p>维佳影院（阳新店）：湖北省黄石市阳新县兴国镇兴国大道3号阳新城市广场4层</p>
                </div>
              </div>
              <div class="contact_ConOneMsg">
                <i class="el-icon-location contact_ConOneicon"></i>
                <div>
                  <p>维佳影院（京山店）：湖北省荆门市京山市城中路246号京山天地5层</p>
                </div>
              </div>


            </div>
            <div class="contact_ConOne">
              <h1>
                文化公司-乐园
              </h1>
              <div class="contact_ConOneMsg">
                <i class="el-icon-location contact_ConOneicon"></i>
                <div>
                  <p>维佳欢乐小镇：湖北省武汉市洪山区文昌路2号</p>
                </div>
              </div>
              <div class="contact_ConOneMsg">
                <i class="el-icon-location contact_ConOneicon"></i>
                <div>
                  <p>维佳童话镇（南湖店）：湖北省武汉市洪山区丁字桥南路525号维佳体验城3层</p>
                </div>
              </div>
              <div class="contact_ConOneMsg">
                <i class="el-icon-location contact_ConOneicon"></i>
                <div>
                  <p>维佳童话镇（徐东店）：湖北省武汉市洪山区团结大道铁机汽车展销中心内</p>
                </div>
              </div>
              <div class="contact_ConOneMsg">
                <i class="el-icon-location contact_ConOneicon"></i>
                <div>
                  <p>维佳真冰俱乐部：湖北省武汉市洪山区丁字桥南路525号维佳体验城4层</p>
                </div>
              </div>


            </div>

            <div class="newMedia">
              <h2>新媒体矩阵</h2>
              <div class="contact_ConOnebBox">
                <div class="WJgroupHonor-leftIcon WJgroupHonor-Icon" @mousemove="leftArrowShow" @click="moveRight">
                  <i class="el-icon-arrow-left"></i>
                </div>
                <div class="WJgroupHonor-rightIcon WJgroupHonor-Icon" @mousemove="rightArrowShow" @click="moveLeft">

                  <i class="el-icon-arrow-right"></i>
                </div>
                <div class="contact_ConOneb" ref="atlasulBox">
                  <ul :style="{'width':ulwidth+'px','left':leftMove+'px'}">
                    <li v-for="(item,index ) in codeList" :key="index">
                      <img :src="item.img" alt="">
                      <p>{{item.name}}</p>
                    </li>

                  </ul>
                </div>

              </div>

            </div>


          </div>


        </div>
      </el-col>
    </el-row>
    <footerb></footerb>
  </div>
</template>
<script>
  export default {
    name: "WJgroupIntroduce",
    components: {},
    data() {
      return {
        menuActiveIndex: '/personnelContact',
        menu: {
          name: '人才招聘',
          path: '/personnel',
          list: [{
            name: '加入维佳',
            path: '/personnel',
          }, {
            name: '联系方式',
            path: '/personnelContact',
          }]
        },
        breadcrumb: [{
          name: '人才招聘',
          path: '/personnel',
        }, {
          name: '联系方式',
          path: '/personnelContact',
        }],
        liBoxwidth: 180,
        ulwidth: null,
        leftMove: 0,
        lArrowShow: false,
        rArrowShow: false,
        codeList: [{
          img: require('../../assets/image/personnel/wjkj.png'),
          name: '维佳空间公众号'
        }, {
          img: require('../../assets/image/personnel/wjbgc.png'),
          name: '维佳佰港城'
        }, {
          img: require('../../assets/image/personnel/bgjd.png'),
          name: '佰港酒店'
        }, {
          img: require('../../assets/image/personnel/qs.png'),
          name: '青舍酒店'
        }, {
          img: require('../../assets/image/personnel/wy.png'),
          name: '物业公司'
        }, {
          img: require('../../assets/image/personnel/mf.png'),
          name: '明芳铁机汽车展销中心'
        }, {
          img: require('../../assets/image/personnel/mf.png'),
          name: '明芳铁机汽车展销中心'
        }, {
          img: require('../../assets/image/personnel/mf.png'),
          name: '明芳铁机汽车展销中心'
        }, {
          img: require('../../assets/image/personnel/mf.png'),
          name: '明芳铁机汽车展销中心'
        }, {
          img: require('../../assets/image/personnel/mf.png'),
          name: '明芳铁机汽车展销中心'
        }, {
          img: require('../../assets/image/personnel/mf.png'),
          name: '明芳铁机汽车展销中心'
        }]
      };
    },
    computed: {},
    created() {},
    mounted() {
      new this.$wow.WOW().init()
      this.$nextTick(() => {
        let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
        this.ulwidth = (this.liBoxwidth * this.codeList.length)
        if (this.ulwidth < ulBoxwidth) {
          this.ulwidth = ulBoxwidth
        }
      })
    },
    methods: {
      leftArrowShow() {
        this.lArrowShow = false
        this.rArrowShow = true
      },
      rightArrowShow() {
        this.rArrowShow = false
        this.lArrowShow = true
      },
      moveLeft() {
        let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
        let moveWith = ulBoxwidth - this.ulwidth;

        if (this.leftMove > moveWith) {
          let Move = Math.abs(moveWith - this.leftMove)
          if (Move < this.liBoxwidth) {
            this.leftMove = this.leftMove - Move
          } else {
            this.leftMove = this.leftMove - this.liBoxwidth
          }


        }
      },
      moveRight() {
        let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
        let moveWith = ulBoxwidth - this.ulwidth;
        let moveOne = this.ulwidth / (this.codeList.length - 1)
        if (this.leftMove < 0) {
          let Move = Math.abs(moveWith - this.leftMove)
          if (Move > this.liBoxwidth) {
            this.leftMove = 0
          } else {
            this.leftMove = this.leftMove + this.liBoxwidth
          }
        }
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .commercial {
    .banner {
      width: 100%;
      height: 550px;

      img {
        width: 100%;
        height: 550px;
      }
    }

    .comContent {
      width: 100%;
      margin: 0 auto;
      // display: flex;
      // background:url(../../assets/image/personnel/imgbg.png) no-repeat bottom;
      // background-size: 100% auto;

      .comConntentLe {
        width: 312px;
        height: 422px;

        h2 {
          width: 146px;
          height: 36px;
          margin-top: 54px;
          font-size: 36px;
          line-height: 36px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #202c38;
        }

        .line {
          width: 33px;
          margin-top: 22px;
          height: 3px;
          background: #1f2d3a;
        }

        h3 {
          margin-top: 57px;
          height: 20px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #1f2d3a;
          line-height: 20px;
          margin-bottom: 21px;
        }

        .conlist2 {
          height: 69px;
          line-height: 69px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #cccc;
          margin-right: 9px;

          .font {
            height: 20px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1f2d3a;
          }

          ::v-deep .el-icon-plus {
            margin-top: 27px;
            width: 14px;
            height: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #aaaaaa;
            line-height: 20px;
          }
        }

        .conlist1 {
          border-bottom: 1px solid #cccc;
          padding: 20px 0;

          .commercialAdd {
            margin-right: 9px;
            margin-top: 26px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 28px;

            span {
              height: 28px;
              font-size: 28px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #af8e68;
              line-height: 28px;
            }

            i {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #aaaaaa;
              // line-height: 14px;
            }
          }

          h3 {
            display: block;
            margin-top: 9px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            color: #aaaaaa;
            line-height: 20px;
          }

          .thank {
            display: block;

            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #202c38;
            line-height: 24px;
          }
        }
      }

      .comContentRi {
        // width: 985px;
        flex: 1;
        // height: 1582px;

        margin-top: 150px;
        margin-bottom: 150px;
        background-color: #fcfafa;
        padding: 30px 100px;

        .house {
          .num {
            font-size: 93px;
            font-family: Impact;
            font-weight: 400;
            color: #af8e68;
          }

          .numRe {
            margin-left: 30px;
          }

          .com {
            display: flex;

            .qualityHouse {
              font-size: 36px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #1f2d3a;
              line-height: 40px;

            }

            .eng {
              height: 21px;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 300;
              color: #aaaaaa;
              margin-left: 11px;
            }
          }

          p {

            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #1F2D3A;
            line-height: 24px;
            margin-top: 10px;

          }
        }
      }
    }



  }

  .personnel_ul {
    width: 100%;

  }

  .personnel_ul li {
    margin: 20px 0;
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #F6EFE5;
  }

  .personnel_ul .personnel_ul_img {
    width: 320px;
    height: 150px;
  }

  .personnel_ul .personnel_ul_img img {
    width: 100%;
    height: 100%;
  }

  .personnel_ul .personnel_ul_content {
    flex: 1;
    margin-left: 40px;
  }

  .personnel_ul .personnel_ul_content h2 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #202C38;
    line-height: 40px;
    height: 40px;
  }

  .personnel_ul .personnel_ul_content p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #6B7B88;
    line-height: 24px;
    height: 72px;
    width: 100%;
  }

  .contact_ConBox {
    background: url(../../assets/image/personnel/bg.png) no-repeat;
    background-size: cover;
    padding: 90px 60px;
    // margin-bottom: 60px;
  }

  .contact_ConOne {
    padding: 30px 50px;
    color: #1F2D39;
    margin-bottom: 12px;
    width: auto;
    background-color: #fff;
  }

  .WJgroupHonor-Icon {
    width: 41px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // border: 2px solid #b08e68;
    background-color: #efebe7;
    border-radius: 50%;
    position: absolute;
    top: 98px;
    cursor: pointer;
    transition: 1s;
  }

  .WJgroupHonor-Icon:hover {
    background-color: #b08e68;

  }

  .WJgroupHonor-Icon:hover i {
    color: #ffffff !important;

  }

  .WJgroupHonor-Icon i {
    color: #b08e68;
    transition: 1s;
    font-size: 30px
  }

  .WJgroupHonor-leftIcon {
    left: 10px;
  }

  .WJgroupHonor-rightIcon {
    right: 10px;
  }

  .contact_ConOnebBox {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 220px;
    position: relative;
    background-color: #FFFFFF;
    padding: 40px 20px;
  }



  .contact_ConOneb {
    // padding: 30px 50px;
    color: #1F2D39;
    width: auto;
    margin-left: 50px;
    margin-right: 50px;
    height: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
  }

  .contact_ConOneb::-webkit-scrollbar {
    display: none;
  }




  .contact_ConOne h1 {
    font-size: 24px;
    // font-family: OPPOSans-B-, OPPOSans-B;
    font-weight: normal;
    color: #1F2D39;
    line-height: 26px;
    letter-spacing: 2px;

  }

  .contact_ConOneMsg {
    margin-top: 16px;
    display: flex;
    align-items: flex-start;
    transition: 1s;
    position: relative;
    padding-left: 40px;

    &:hover {
      .contact_ConOneicon {
        top: 0 !important;
      }

      .contact_Conicon {
        top: 0 !important;
      }
    }
  }

  .contact_Conicon {
    position: absolute;
    top: 28%;
    left: 0;
  }

  .contact_ConOneMsg i {
    font-size: 26px;
    margin-right: 10px;
    transition: 0.3s;
  }

  .contact_ConOneMsg .contact_ConOneicon {
    position: absolute;
    top: 12%;
    left: 0;
  }

  .contact_ConOneMsg p {
    cursor: default;
    font-size: 16px;
    // font-family: OPPOSans-R-, OPPOSans-R;
    font-weight: normal;
    color: #1F2D39;
    line-height: 32px;
    letter-spacing: 2px;
  }

  .newMedia h2 {
    margin: 20px 0 24px;
    font-size: 32px;
    // font-family: OPPOSans-B-, OPPOSans-B;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 44px;
    letter-spacing: 2px;
  }

  .newMedia ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: 0.5s;
  }

  .newMedia ul li {
    text-align: center;
    width: 160px;
    padding: 0 10px;
  }

  .newMedia ul li img {
    width: 160px;
    height: 160px;
  }

  .newMedia ul li p {
    font-size: 14px;
    // font-family: OPPOSans-R-, OPPOSans-R;
    font-weight: normal;
    color: #1F2D39;
    line-height: 20px;
    margin-top: 10px;
  }
</style>
